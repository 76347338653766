import { makeAutoObservable } from 'mobx';
import moment from 'moment';

class FilterStore {
  filters = {
    dataRange: {
      start: moment(moment().format('YYYY-MM-DD') + ' 00:00'),
      end: null,
    },
    onlyNotStarted: false,
  };

  constructor() {
    makeAutoObservable(this);
    this.checkStored();
  }

  checkStored() {
    const json = localStorage.getItem('filters');
    if (json) {
      const _filters = JSON.parse(json);
      this.filters = _filters;
      if (_filters.dataRange)
        this.filters.dataRange = {
          start: moment(_filters.dataRange.start),
          end: _filters.dataRange.end ? moment(_filters.dataRange.end) : null,
        };
    }
  }

  saveFilters(_filters) {
    this.filters = _filters;
    localStorage.setItem('filters', JSON.stringify(_filters));
  }
}

const store = new FilterStore();

export default store;
