import axios from 'axios';
import moment from 'moment';

import { fetchAll, toast } from '../helpers/utils';
import AuthStore from '../store/auth';
import EventsStore from '../store/events';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

// USERS
export const getUser = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/${userId}/one/get`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

const eventParticipantsLimit = 100;
const cacheEventParticipants = [];

export async function getEventParticipants(eventId, relationships) {
  if (cacheEventParticipants.length > 0) return cacheEventParticipants;

  let offset = 0;

  async function _getEventParticipants() {
    const result = await fetchEventParticipants(
      eventId,
      relationships,
      eventParticipantsLimit * offset
    );
    if (!result) {
      return cacheEventParticipants;
    }

    cacheEventParticipants.push(...result.list);
    if (cacheEventParticipants.length < result.total_count) {
      offset++;
      await _getEventParticipants();
    }
  }

  await _getEventParticipants();

  return cacheEventParticipants;
}

const fetchEventParticipants = async (eventId, relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/event/${eventId}/participants/many/get`,
      { relationships, offset, limit: eventParticipantsLimit },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMyParticipants = async (relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscribers/many/accepted/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMyPendings = async (relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscribers/many/pending/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const acceptFollower = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscriber/${userId}/accept`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const cancelFollower = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/my/subscriber/${userId}/cancel`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserFollowers = async (userId, relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/users/${userId}/subscribers/many/accepted/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// PROFILES

export const getUserProfile = async (userId, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/profiles/user/${userId}/one/get`,
      { relationships: [relationships] },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// EVENTS
export const checkMySubscriptionOnEvent = async (event_id) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/event-participants/my/event/${event_id}/exists`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getEventsByArea = async (area, offset, filters, relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/many/area/get`,
      {
        ...area,
        filter_paid: filters.paid,
        filter_category_type_ids: filters.category,
        filter_active: filters.onlyNotStarted,
        filter_start_at: moment(filters.dataRange?.start).utc().format(),
        filter_end_at: filters.dataRange?.end
          ? moment(filters.dataRange?.end).utc().format()
          : null,
        relationships: relationships,
        offset,
      },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getEvents = async (
  limit,
  offset,
  city_id,
  filters,
  relationships
) => {
  return axios
    .postForm(
      city_id != null
        ? `${API_ENDPOINT}/events/city/${city_id}/many/get`
        : `${API_ENDPOINT}/events/many/get`,
      {
        limit,
        offset,
        filter_format: filters.format,
        filter_paid: filters.paid,
        filter_category_type_ids: filters.category,
        filter_active: filters.onlyNotStarted,
        filter_start_at: moment(filters.dataRange?.start).utc().format(),
        filter_end_at: filters.dataRange?.end
          ? moment(filters.dataRange?.end).utc().format()
          : null,
        relationships,
      },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const saveEventCover = async (eventId, fileBlob, fileName) => {
  const formData = new FormData();
  formData.append('cover', fileBlob, fileName);

  return axios
    .postForm(`${API_ENDPOINT}/events/${eventId}/cover/save`, formData, {
      headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
    })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const participate = async (eventId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/${eventId}/participate`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const unparticipate = async (eventId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/${eventId}/unparticipate`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      EventsStore.unsubscribe(eventId);
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMySubscribeEvents = async (relationships, offset, limit) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/my/many/participation/get`,
      { relationships, offset, limit },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMyEvents = async (relationships, offset, limit) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/my/many/ownership/get`,
      { relationships, offset, limit },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

let cachedUserEvents = [];

const _getUserEvents = async (userId, relationships, offset, limit) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/user/${userId}/many/ownership/get`,
      { relationships, offset, limit },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserEvents = async (userId, relationships) => {
  const result = await fetchAll(_getUserEvents, cachedUserEvents, 100, [
    userId,
    relationships,
  ]);
  cachedUserEvents = result;
  return result;
};

let cachedUserSubscriveEvents = [];

const _getUserSubscribeEvents = async (
  userId,
  relationships,
  offset,
  limit
) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/events/user/${userId}/many/participation/get`,
      { relationships, offset, limit },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserSubscribeEvents = async (userId, relationships) => {
  const result = await fetchAll(
    _getUserSubscribeEvents,
    cachedUserSubscriveEvents,
    100,
    [userId, relationships]
  );
  cachedUserSubscriveEvents = result;
  return result;
};

//USER SUBSCRIPTIONS

export const getUserSubscriptionExists = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/exists`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data.result;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserSubscription = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/one/get`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      if (response.status === 204) return false;
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getUserSubscribes = async (userId, relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/user/${userId}/many/accepted/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const getMySubscribes = async (relationships, offset) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/many/get`,
      { relationships: [relationships], offset },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const subscribeUser = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/create`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const unSubscribeUser = async (userId) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/user-subscriptions/my/user/${userId}/delete`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// VERIFICATION

export const sendEmailVerification = async () => {
  return axios
    .postForm(
      `${API_ENDPOINT}/verification/email/notify`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      toast('Письмо успешно отправлено на ваш email', 'success');
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const varificateEmail = async (url) => {
  return axios
    .post(url)
    .then((response) => {
      toast('Ваш email успешно подтвержден!', 'success');
      AuthStore.setVerified(true);
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// BOOKMARKS
let cachedBookmarks = [];
export const getAllBookmarks = async (relationships) => {
  const result = await fetchAll(getBookmarks, cachedBookmarks, 100, [
    relationships,
  ]);
  cachedBookmarks = result;
  return result;
};

const getBookmarks = async (relationships) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/bookmarks/my/many/get`,
      { relationships },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const addBookmark = async (event_id) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/bookmarks/event/${event_id}/add`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      cachedBookmarks = [];
      EventsStore.addBookmark(event_id);
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const removeBookmark = async (event_id) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/bookmarks/event/${event_id}/remove`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      cachedBookmarks = [];
      EventsStore.removeBookmark(event_id);
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const checkEventBookmark = async (event_id) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/bookmarks/event/${event_id}/exists`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      if (response.data.result == true)
        EventsStore.addBookmark(parseInt(event_id));
      else EventsStore.removeBookmark(parseInt(event_id));
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// DICTIONARIES
let cachedCities = [];

export const getAllCities = async () => {
  if (cachedCities.length > 0) return cachedCities;

  const result = await fetchAll(getCities, cachedCities, 1000);

  cachedCities = result.map((c) => {
    return {
      id: c.id,
      label: c.name,
    };
  });

  return cachedCities;
};

const getCities = async (offset, limit) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/dicts/city/many/get`,
      { offset, limit },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

let cacheCategories = {
  total_count: 0,
  list: [],
};

export const getCategories = async () => {
  if (cacheCategories.total_count > 0) return cacheCategories;

  return axios
    .postForm(
      `${API_ENDPOINT}/dicts/event-category-type/many/get`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      cacheCategories = response.data;
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// EVENT MOMENTS
const eventMoments = {
  total_count: 0,
  list: [],
};
export const getEventMoments = async (
  event_id,
  relationships,
  offset,
  limit
) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/event-moments/event/${event_id}/many/get`,
      { relationships, offset, limit },
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      eventMoments.total_count = response.data.total_count;
      eventMoments.list.splice(offset, limit, ...response.data.list);
      return eventMoments;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const saveEventMoment = async (event_id, fileBlob, fileName) => {
  const formData = new FormData();
  formData.append('photo_moment', fileBlob, fileName);

  return axios
    .postForm(
      `${API_ENDPOINT}/event-moments/event/${event_id}/save`,
      formData,
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

export const deleteEventMoment = async (moment_id, index) => {
  return axios
    .postForm(
      `${API_ENDPOINT}/event-moments/${moment_id}/delete`,
      {},
      {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      }
    )
    .then((response) => {
      eventMoments.list.splice(index, 1);
      return true;
    })
    .catch((error) => {
      errorHandler(error);
      return false;
    });
};

// ERROR HANDLER
const errorHandler = (data) => {
  if (data.response?.data) toast(data.response.data.message, 'error');
  else toast('Неизвестная ошибка', 'error');
};
