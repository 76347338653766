import CircularProgress from '@mui/material/CircularProgress';

import { createPortal } from 'react-dom';

import styles from './styles.module.scss';

export default function Loader(props) {
  const { directly } = props;

  const loader = (
    <div className={styles.loaderOverlay}>
      <CircularProgress color="inherit" />
    </div>
  );

  if (directly) return loader;

  const loaderPortal = document.querySelector('#loader');

  if (loaderPortal) {
    return createPortal(loader, loaderPortal);
  } else return <div>ЗАГРУЗКА...</div>;
}
