import { cyan } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'moment/locale/ru';

import { observer } from 'mobx-react-lite';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import WebFont from 'webfontloader';

import styles from './App.module.scss';
import ProfileModal from './components/profileModal';
import { useAuthStore, useProfileStore } from './store/index';
import Loader from './ui/components/loader';

const MainRoutes = lazy(() => import('./components/routes/mainRoutes'));
const StartRoutes = lazy(() => import('./components/routes/startRoutes'));

const primaryColors = {
  50: '#e1f5fe',
  100: '#b3e5fc',
  200: '#81d4fa',
  300: '#4fc3f7',
  400: '#29b6f6',
  500: '#ff7d31',
  600: '#039be5',
  700: '#ff650b',
  800: '#0277bd',
  900: '#01579b',
  A100: '#80d8ff',
  A200: '#40c4ff',
  A400: '#00b0ff',
  A700: '#0091ea',
};

const theme = createTheme({
  palette: {
    primary: primaryColors,
    secondary: cyan,
  },
  typography: {
    fontFamily: 'RobotoB',
    lineHeight: 'normal',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'RobotoR',
      },
    },
  },
});

//MOCK DATA
// if (window.location.origin == 'http://localhost') {
//   if (!window.Telegram) {
//     window.Telegram = {
//       WebApp: {
//         backgroundColor: '',
//       },
//       WebView: {
//         initParams: {
//           tgWebAppData: '',
//         },
//       },
//     };
//   }
// }

const App = observer((props) => {
  const authStore = useAuthStore();
  const profileStore = useProfileStore();
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.PrimeToast = toast || {};

    if (window.Telegram?.WebView.initParams) {
      authStore.isTgWebApp = true;
      window.Telegram.WebApp.backgroundColor = '#ffffff';
      if (authStore.isTelegramAuth) {
        authStore.checkAccessTokenFromTelegramCloud();
      }
    }

    const webFontConfig = {
      custom: {
        families: ['RobotoR', 'RobotoB'],
      },
      active: () => setFontsLoaded(true),
      timeout: 30000,
    };

    WebFont.load(webFontConfig);
  }, []);

  const checkTgParams = () => {
    const params = window.Telegram?.WebApp.initDataUnsafe?.start_param;
    if (params) {
      const [param, value] = params.split('_');
      switch (param) {
        case 'user':
          navigate(`/user/${value}`);
          break;
        case 'event':
          navigate(`/event/${value}`);
          break;
      }
    }
  };

  useEffect(() => {
    async function fetchMe() {
      setLoading(true);
      const response = await authStore.getMe();
      if (response) {
        const emailVerified = response?.email_verified_at != null;

        const profile = await profileStore.getProfile('avatar.file');
        if (!profile && (emailVerified || authStore.isTgWebApp)) {
          setProfileModal(true);
        }

        authStore.setVerified(emailVerified);

        if (authStore.isTgWebApp) checkTgParams();
      }
      setLoading(false);
    }
    if (authStore.accessToken) {
      fetchMe();
    }
  }, [authStore.accessToken]);

  if (!fontsLoaded)
    return (
      <div className={styles.AppWrapper}>
        <Loader />
        <div id="loader" />
      </div>
    );

  let routes = null;

  if (!loading)
    if (
      !authStore.accessToken ||
      (!authStore.verified && !authStore.isTelegramAuth)
    ) {
      routes = (
        <StartRoutes
          verified={authStore.verified}
          accessToken={authStore.accessToken}
        />
      );
    } else {
      routes = <MainRoutes />;
    }

  return (
    <div className={styles.AppWrapper}>
      <ThemeProvider theme={theme}>
        <div className={styles.App}>
          <ToastContainer />
          {profileModal ? (
            <ProfileModal onClose={() => setProfileModal(false)} />
          ) : (
            <Suspense fallback={<Loader directly={true} />}>{routes}</Suspense>
          )}
        </div>
        {loading && <Loader />}
        <div id="loader" />
      </ThemeProvider>
    </div>
  );
});

export default App;
