import axios from 'axios';
import { makeAutoObservable } from 'mobx';

import {
  getMyEvents as _getMyEvents,
  getMySubscribeEvents as _getMySubscribeEvents,
  getAllBookmarks,
} from '../helpers/fetch';
import { toast } from '../helpers/utils';
import AuthStore from './auth';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

class EventsStore {
  myEvents = [];
  mySubscribeEvents = [];
  bookmarks = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getMyEvents(relationships) {
    if (this.myEvents.length > 0) return this.myEvents;

    const events = [];
    const limit = 100;
    let offset = 0;

    async function _getEvents() {
      const result = await _getMyEvents(relationships, limit * offset, limit);
      if (!result) {
        return;
      }

      events.push(...result.list);
      if (events.length < result.total_count) {
        offset++;
        await _getEvents();
      } else {
        return;
      }
    }

    await _getEvents();
    this.setMyEvents(events);
    return events;
  }

  async getMySubscribeEvents(relationships) {
    if (this.mySubscribeEvents.length > 0) return this.mySubscribeEvents;

    const events = [];
    const limit = 100;
    let offset = 0;

    async function _getEvents() {
      const result = await _getMySubscribeEvents(
        relationships,
        limit * offset,
        limit
      );
      if (!result) {
        return;
      }

      events.push(...result.list);
      if (events.length < result.total_count) {
        offset++;
        await _getEvents();
      } else {
        return;
      }
    }

    await _getEvents();
    this.setMySubscribeEvents(events);
    return events;
  }

  async getEvent(eventId, relationships) {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/${eventId}/one/get`,
        { relationships: relationships },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async deleteEvent(eventId) {
    return axios
      .postForm(
        `${API_ENDPOINT}/events/${eventId}/delete`,
        {},
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.removeEvent(eventId);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async getMyBookmarks(relationships) {
    const result = await getAllBookmarks(relationships);
    this.setMyBookmarks(result);
    return result;
  }

  addBookmark(event_id) {
    if (this.bookmarks.some((e) => e.id === event_id)) return;

    this.bookmarks.push({
      id: event_id,
    });
  }

  removeBookmark(event_id) {
    this.bookmarks = this.bookmarks.filter((e) => e.id !== event_id);
  }

  setMyEvents(events) {
    this.myEvents = events;
  }

  setMySubscribeEvents(events) {
    this.mySubscribeEvents = events;
  }

  setMyBookmarks(bookmarks) {
    this.bookmarks = bookmarks;
  }

  removeEvent(eventId) {
    // возможно не нужный код, т.к. всё-равно идёт запрос по новой при заходе в профиль
    // this.myEvents = this.myEvents.filter((e) => e.id !== eventId);
    // this.mySubscribeEvents = this.mySubscribeEvents.filter(
    //   (e) => e.id !== eventId
    // );
    // this.bookmarks = this.bookmarks.filter((e) => e.id !== eventId);
  }

  unsubscribe(eventId) {
    this.mySubscribeEvents = this.mySubscribeEvents.filter(
      (e) => e.id !== eventId
    );
  }

  async createEvent(data) {
    return axios
      .postForm(`${API_ENDPOINT}/events/new/save`, data, {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      })
      .then((response) => {
        this.addNewEvent(response.data);
        return response.data;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  addNewEvent(data) {
    this.myEvents.push(data);
  }

  reset() {
    this.myEvents = [];
    this.mySubscribeEvents = [];
    this.bookmarks = [];
  }

  errorHandler(data) {
    if (data.response?.data) toast(data.response.data.message, 'error');
    else toast('Неизвестная ошибка', 'error');
  }
}

const store = new EventsStore();

export default store;
