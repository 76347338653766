import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import { toast } from '../helpers/utils';
import AuthStore from './auth';

const API_ENDPOINT = process.env.REACT_APP_API_URL;

class ProfileStore {
  id = '';
  username = '';
  firstName = '';
  lastName = '';
  middleName = '';
  description = '';
  birthDate = '';
  age = 0;
  sex = '';
  avatar = '';
  followersCount = 0;
  pendingCount = 0;
  subscribesCount = 0;

  constructor() {
    makeAutoObservable(this);

    this.checkStored();
  }

  checkStored() {
    const profile = localStorage.getItem('profile');
    if (profile) {
      const profileObj = JSON.parse(profile);
      this.id = profileObj.id;
      this.username = profileObj.username;
      this.firstName = profileObj.firstName || '';
      this.lastName = profileObj.lastName || '';
      this.middleName = profileObj.middleName || '';
      this.description = profileObj.description || '';
      this.birthDate = profileObj.birthDate;
      this.age = profileObj.age;
      this.followersCount = profileObj.followersCount || 0;
      this.pendingCount = profileObj.pendingCount || 0;
      this.subscribesCount = profileObj.subscribesCount || 0;
    }
  }

  async getProfile(relationships) {
    return axios
      .postForm(
        `${API_ENDPOINT}/profiles/my/one/get`,
        { relationships: [relationships] },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        if (response.status === 204) {
          return false;
        }
        this.setProfileData(response.data);
        return true;
      })
      .catch((error) => {
        if (error.response.status == '403') return false;
        this.errorHandler(error);
        return false;
      });
  }

  setProfileData(data) {
    this.id = data.user_id;
    this.username = data.username;
    this.firstName = data.first_name;
    this.lastName = data.last_name || '';
    this.middleName = data.middle_name || '';
    this.description = data.description || '';
    this.birthDate = data.birth_date;
    this.sex = data.sex;
    this.age = moment().diff(moment(data.birth_date, 'YYYYMMDD'), 'years');
    this.followersCount = data.accepted_subscribers_count;
    this.pendingCount = data.pending_subscribers_count;
    this.subscribesCount = data.subscriptions_count;

    localStorage.setItem(
      'profile',
      JSON.stringify({
        id: data.user_id,
        username: data.username,
        firstName: data.first_name || '',
        lastName: data.last_name || '',
        middleName: data.middle_name || '',
        description: data.description || '',
        birthDate: data.birth_date,
        sex: data.sex,
        age: this.age,
        followersCount: this.followersCount || 0,
        pendingCount: this.pendingCount || 0,
        subscribesCount: this.subscribesCount || 0,
      })
    );

    this.avatar = data.avatar?.file.url || '';
  }

  async saveProfile(data) {
    const newData = {
      username: this.username,
      first_name: this.firstName,
      last_name: this.lastName,
      middle_name: this.middleName,
      description: this.description,
      sex: this.sex,
      birth_date: this.birthDate,
      ...data,
    };

    return axios
      .postForm(
        `${API_ENDPOINT}/profiles/my/save`,
        { ...newData },
        {
          headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
        }
      )
      .then((response) => {
        this.setProfileData(response.data);
        toast('Настройки профиля успешно сохранены', 'success');
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  async saveAvatar(fileBlob, fileName) {
    const formData = new FormData();
    formData.append('avatar', fileBlob, fileName);
    formData.append('relationships[]', 'file');
    return axios
      .postForm(`${API_ENDPOINT}/profiles/my/avatar/save`, formData, {
        headers: { Authorization: `Bearer ${AuthStore.accessToken}` },
      })
      .then((response) => {
        this.setProfileAvatar(response.data.file.url);
        return true;
      })
      .catch((error) => {
        this.errorHandler(error);
        return false;
      });
  }

  setProfileAvatar(avatar) {
    this.avatar = avatar;
  }

  errorHandler(data) {
    if (data.response?.data) toast(data.response.data.message, 'error');
    else toast('Неизвестная ошибка', 'error');
  }
}

const store = new ProfileStore();

export default store;
